.search_popup_wrapper {
  position: fixed;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  padding-top: 30px;
  // height: 180px;
  padding-left: 9.3%;
  /* 화이트 */
  background: #ffffff;
  /* 그림자_gnb */
  box-shadow: 3px 4px 20px rgba(194, 203, 214, 0.15);

  .search_popup {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search_box {
    width: 100px;
  }

  .icon {
    width: 60px;
    height: 60px;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// For Mobiles and small screen 481px and less:
@media screen and (min-width: 220px) and (max-width: 768px) {
  .search_popup_wrapper {
    display: none;
  }
}

// --------Mobile--------

@media screen and (max-width: 768px) {
  .search_popup_wrapper_mobile {
    display: flex;
    padding: 0px;

    .modal-content {
      max-width: 600px;
      margin: auto;
      box-shadow: 0px 10px 15px rgba(71, 71, 71, 0.1);
      border-radius: 20px;
      padding: 0px;
    }

    .modal-header {
      border: none;
    }

    .search-text {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.03em;
    }
  }
}
