.dna_card {
  /* dna test card1 */
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  padding: 60px 20px;
  width: 383px;
  height: 327px;
  background: #eef5ff;
  box-shadow: 0px 5px 15px rgba(0, 91, 226, 0.1);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;

  .cardContent {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #272727;
  }
}

// For Desktops, large screens 1025px — 1200px
@media screen and (max-device-width: 1200px) {
  .dna_card {
    padding: 30px 25px;
    width: 300px;
    height: 300px;
  }
}

// For Small screens, laptops 769px — 1024px
@media screen and (max-device-width: 1024px) {
  .dna_card {
    padding: 20px 15px;
    width: 240px;
    // height: 327px;
  }
}

// For Mobiles and small screen 481px and less:
@media screen and (min-device-width: 220px) and (max-device-width: 480px) {
  .dna_card {
    gap: 30px;
    padding: 30px 25px;
    width: 270px;
    height: 327px;
  }
}

.mobile-card {
  background-color: #eef5ff;
  border-radius: 8px;
  // margin-top: 1px;
  font-size: 13px;
  height: 3rem;
  line-height: 16.8px;
  width: 21.5rem;
  text-align: left !important;

}

.mobile-image {
  width: 2rem;
  height: 2rem;
}