.login-modal-wrapper {
  width: 760px;
  height: 792px;

  .modal-header {
    border: none;
  }

  .input-icons svg {
    position: absolute;
  }

  .input-icons {
    width: 100%;
  }

  .icon {
    margin: 5px;
  }

  .input-field {
    width: 100%;
    padding: 23px 0px 23px 80px;
    text-align: left;
  }

  .login-text {
    width: 593px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 48px;
    color: #272727;
  }
}

//animation
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ResultModal {
  background-image: url("../../../assets/images/ResultModal.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  //width: 402.13px;
  //height: 250px;
  position: relative;
}
.loading {
  position: relative;
  top: 38px;
  right: 11px;
  animation: spin 2s linear infinite;
}

.spinner-container {
  margin-top: 2rem;
  margin-bottom: 8rem;
}

.modalContent {
  width: 651px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalBoday {
  width: 593px;
  padding: 30px 30px;
}

.textModal {
  width: 68%;
}

// For Tablets or iPad: 480px - 768px
@media screen and (min-device-width: 480px) and (max-device-width: 768px) {
  .login-modal-wrapper {
    width: 310px;
    height: 410px;
    .login-text {
      font-size: 20px;
      line-height: 10px;
    }
  }
  .ResultModal {
    width: 201px;
    height: 125px;
    .loading {
      top: 0px;
      left: 40px;
      height: 120px;
    }
  }
}

.result-heading {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
  color: #272727;
  text-align: center;
}

.result-subheading{
  font-family: 'Pretendard';
  text-align: center;
  font-size: 15px;
  width: 100%;
}

// For Mobile devices: 320px-480px
@media screen and (min-device-width: 220px) and (max-device-width: 480px) {
  .login-modal-wrapper {
    width: 310px;
    height: 410px;
    .login-text {
      font-size: 20px;
      line-height: 10px;
    }
  }
  .ResultModal {
    width: 201px;
    height: 125px;
    .loading {
      top: 0px;
      left: 40px;
      height: 120px;
    }
  }
}

@media only screen and (min-width: 600px) {
  .result-modal-wrapper .modal-content {
    max-width: 435px;
    height: 483px;
  }
  .result-heading{
    font-size: 24px;
    margin-bottom: 40px;
    line-height: 24px;
  }
  .result-subheading{
    font-size: 12px;
    width: 231px;
    color:#272727;
    margin-bottom: 0;
  }
  .spinner-container{
    margin-top: 0px;
  }
  .loading-bg{
    width: 214.13px;
    height: 286.32px;
  }
  .loading {
    width: 114.21px;
    height: 212.21px;
    top: 29px;
    right: 11px;
  }
}

@media screen and (min-width: 1440px){
  .result-modal-wrapper .modal-content {
    max-width: 760px;
    height: 792px;
  }

  .result-heading{
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 60px;
  }

  .result-subheading{
    font-size: 20px;
    width: 400px;
    font-weight: 400;
    color:#272727;
  }
  .spinner-container{
    margin-top: 51px;
  }
  .loading-bg{
    width: 402.13px;
    height: 286.32px;
  }

  .loading {
    width: 212.21px;
    height: 212.21px;
    top: 52px;
    right: 18px;
  }
}