.signup_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 80px 0px;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form {
    width: 49%;
    padding: 20px 20px 20px 20px;
    .fields {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 10px;
      text-align: left;
      padding: 20px 20px 15px 20px;
      .label {
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */

        /* main text */

        color: #272727;
      }
      .getVerificationCode {
        display: flex;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        .getVerificationCodeInput {
          width: 100%;
        }
        .getVerificationCodeButton {
          width: 170px;
          height: 60px;
          /* main color */
          background: #005be2;
          border-radius: 8px;

          font-family: "Pretendard";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.03em;

          /* white */
          border: none;

          color: #ffffff;
        }
        .getVerificationCodeButton[disabled] {
          background-color: #81a3d6;
          width: 200px;
          height: 60px;
          cursor: not-allowed;
        }
      }
      .input {
        box-sizing: border-box;
        height: 60px;
        background: #ffffff;
        /* form_1 */
        border: 1.5px solid #cccccc;
        border-radius: 8px;
        width: 100%;
        // placeholder
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        padding-left: 3%;
        /* subtext_2 */

        color: #999999;
      }
      input.error {
        border-color: #ff4d4f; /* Red border for validation error */
      }
      .input[disabled] {
        cursor: not-allowed;
      }
      .error {
        color: red;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        gap: 6px;
        align-items: center;

        &::before {
          display: block;
          height: 16px;
          width: 16px;
          align-self: flex-start;
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M8.75 8.75H7.25V4.25H8.75M8.75 11.75H7.25V10.25H8.75M8 0.5C7.01509 0.5 6.03982 0.693993 5.12987 1.0709C4.21993 1.44781 3.39314 2.00026 2.6967 2.6967C1.29018 4.10322 0.5 6.01088 0.5 8C0.5 9.98912 1.29018 11.8968 2.6967 13.3033C3.39314 13.9997 4.21993 14.5522 5.12987 14.9291C6.03982 15.306 7.01509 15.5 8 15.5C9.98912 15.5 11.8968 14.7098 13.3033 13.3033C14.7098 11.8968 15.5 9.98912 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96018 0.693993 8.98491 0.5 8 0.5Z' fill='%23FF4D4F'/%3E%3C/svg%3E");
        }
      }
      .authButton {
        width: 220px;
        height: 60px;
        /* main color */
        background: #005be2;
        border-radius: 8px;
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.03em;

        /* white */

        color: #ffffff;
        border: none;
      }
    }
    .submit_buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      padding: 55px 0px 0px 0px;
      width: 100%;
      .before {
        width: 240px;
        height: 60px;
        background: #ffffff;
        /* main color */
        border: 1px solid #005be2;
        border-radius: 8px;

        /* 16_semibold */

        font-family: "Pretendard";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.03em;

        /* main color */

        color: #005be2;
      }
      .signup {
        width: 240px;
        height: 60px;
        /* main color */
        background: #005be2;
        border-radius: 8px;

        font-family: "Pretendard";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.03em;
        border: none;
        /* white */

        color: #ffffff;
      }
    }
  }
}
.social-icons h6{
  display: flex;
  margin-top: 20px;
}
.social-icons h6 img{
  margin-right: 20px;
  height: 30px;
  width: 30px;
  margin-top: -10px;
}
.horizontal-line {
  border-bottom: 1px solid #CCCCCC;
  width: 100%;
}
// For Desktops, large screens 1025px — 1200px
@media screen and (max-device-width: 1200px) {
  .signup_wrapper {
    .form {
      width: 70%;
    }
  }
}

// For Small screens, laptops 769px — 1024px
@media screen and (max-device-width: 1024px) {
  .signup_wrapper {
    .form {
      width: 80%;
    }
  }
}

// For iPads, Tablets 481px — 768px:
@media screen and (max-device-width: 768px) {
  .signup_wrapper {
    margin: 0px 0px 100px 0px;
    gap: 15px;
    padding: 0px 20px;
    .logo {
      margin-top: 25px;
    }
    .form {
      width: 100%;
      padding: 0px;
    }
  }
}

// For Mobiles, 481px — 768px:
@media screen and (min-device-width: 320px) and (max-device-width: 488px) {
  .signup_wrapper {
    margin-bottom: 55px;
    .form {
      width: 100%;
      .fields {
        padding: 20px 0px 0px 0px;
        .label {
          font-size: 18px;
        }
        .input {
          height: 50px;
        }
        .getVerificationCode {
          flex-direction: column;
          gap: 5px;
          .getVerificationCodeButton {
            width: 100%;
            height: 50px;
          }
          .getVerificationCodeButton[disabled] {
            width: 100%;
            height: 50px;
          }
        }
        .authButton {
          height: 50px;
          width: 100%;
        }
      }
      .submit_buttons {
        padding: 32px 0px 0px 0px;
        gap: 10px;
        .before {
          height: 50px;
        }
        .signup {
          height: 50px;
        }
      }
    }
  }
}
