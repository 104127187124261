.search-box {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 660px 16px 24px;
  // margin: 0px 0px 20px 0px;
  gap: 8px;
  width: 53vw;
  height: 56px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 100px;
}

input[type="search"] {
  font-size: 16px;
  background-image: url(../../assets/icons/searchIcon.svg);
  background-position: 25px 18px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 55px;
}

@media screen and (max-width: 768px) {
  .search-box {
    padding: 0;
    width: 100%;
    height: 50px;
  }

  input[type="search"] {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400px;
    background-image: url(../../assets/icons/searchIcon.svg);
    background-position: 25px 15px;
  }
  .recentsearch-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    letter-spacing: -0.03em;
  }
  .recentsearch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.03em;

    /* subtext_1 */

    color: #575757;
  }
}
