.question {
  display: flex;
  width: 83%;
  padding: 16px 0px;
  border-bottom: 1px solid #dceaff;
  text-align: left;
  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 30%;
  }
}
.e-btn-group {
  margin: 25px 5px 20px 20px;
}
.question:nth-child(21) {
  border-bottom: none;
  // padding-bottom: 100px;
}

// For Laptop or small-size screen: 768px -1024px
@media screen and (max-device-width: 1024px) {
  .question {
    width: 95%;
    padding: 10px 0px;
  }
}

// For Tablets or iPad: 480px - 768px
@media screen and (max-device-width: 768px) {
  .question {
    .right {
      align-items: flex-end;
      flex-direction: column;
      button {
        margin: 5px 0px;
      }
    }
  }
}

// For Mobile devices: 320px-480px
@media screen and (min-device-width: 220px) and (max-device-width: 480px) {
  .left {
    width: 70%;
  }
  .right {
    display: flex;
    flex-direction: column;
    button {
      margin: 5px 0px;
    }
  }
}

@media screen and (min-width: 768px) {
  /* CSS rules here */
  .question {
    justify-content: space-between;
    align-items: center;
  }
}

// Paginatoin Styling
.buttonsDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
  // background-color: red;
}

.prevAndNextbuttons {
  width: 100px;
  height: 60px;
  margin: 5px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0066ff;
  color: #0066ff;
  background-color: #ffffff;
}
.prevAndNextbuttons:disabled {
  border: 1px solid #74b4ff;
  color: #74b4ff;
}

.buttonLabel {
  text-decoration: none;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: -0.03em;
  color: #005be2;
}

.result_button {
  width: 100px;
  height: 60px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #005be2;
  color: #ffffff;
  border: none;
}
.result_button[disabled] {
  background-color: #6892d2;
}

.prevNextColor {
  border-width: 1px;
  border-color: #005be2 !important;
}

.textFont {
  font-family: "Pretendard";
}
