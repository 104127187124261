.questionHeaderCardContent {
  height: 17px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #272727;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.questionHeaderCard {
  display: flex;
  align-items: center;
  padding: 8px;
  height: 46px;
  background: #eef5ff;
  box-shadow: 0px 5px 15px rgba(0, 91, 226, 0.1);
  backdrop-filter: blur(2px);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.questions_wrapper {
  .hero_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    gap: 50px;
    background-image: url("../../assets/images/QNA_bkgr.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 636px;

    .dna_title {
      /* DNA 질문검사 */
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 58px;
      /* identical to box height, or 138% */
      text-align: center;
      color: #ffffff;
      padding-top: 80px;
    }

    .dna_cards {
      /* dna test dna_cards */
      gap: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .questions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
  }
}

@media screen and (max-width: 767px) {
  .questions_wrapper {
    .hero_section {
      gap: 0px !important;
      height: 335px !important;
      margin-bottom: -2rem;

      .dna_title {
        margin: 30px 0px 20px 0px;
      }

      .dna_cards {
        flex-direction: column;
        gap: 5px !important;
        padding: 0px !important;
      }
    }

    .questions {
      padding-top: 0px;
    }
  }

  .dna_title {
    /* DNA 질문검사 */

    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 24px !important;
    line-height: 36px !important;
    /* identical to box height, or 138% */
    text-align: center;
    color: #ffffff;
    padding-top: 10px !important;
  }
}

// For Mobiles and small screen 481px and less:
@media screen and (min-device-width: 220px) and (max-device-width: 480px) {
  .questions_wrapper {
    .hero_section {
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;
      gap: 50px;
      background-image: url("../../assets/images/QNA_bkgr_mobile.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom;
      height: 636px;
    }
  }
}