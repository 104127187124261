.footer-wrapper {
  /* footer */
  width: 100vw;
  height: 240px;
  /* light background_2 */
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  // z-index: 999;

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    gap: 20px;

    .copyright {
      /* 16_regular */
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -0.03em;
      /* subtext_2 */
      color: #999999;
    }

    .links {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .linktext {
        /* 16_semibold */
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.03em;
        /* main text */
        color: #272727;
        text-decoration: none;
      }
    }
  }
}

// For iPads, Tablets 481px — 768px:
@media screen and (max-device-width: 768px) {
  .footer-wrapper {
    .footer {
      width: 80%;
      gap: 20px;
    }
  }
}

// For Mobiles and small screen 481px and less:
@media screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .footer-wrapper {
    height: 308px;

    .footer {
      width: 100%;
      gap: 20px;

      .links {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.instagram-icon:hover path {
  fill: #bc2a8d;
  /* Change this to the color you want */
}