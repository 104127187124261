.loginFailedModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  border-radius: 20px;
  color: black;
  padding: 0px 30px;
  .modal-content {
    max-width: 760px;
    margin: auto;
    box-shadow: 0px 10px 15px rgba(71, 71, 71, 0.1);
    border-radius: 20px;
    padding: 20px;
  }
  .modal-header {
    border: none;
  }
  .error_heading {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    /* identical to box height */

    /* main text */

    color: #272727;
  }
  .error-text {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    text-align: center;

    /* main text */

    color: #272727;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    .button {
      padding: 10px 5vw;
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.03em;
      min-width: 240px;
      height: 60px;
    }
  }
  .error_image {
    background-image: url(../../../assets/images/ResultModal.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 160px;
    width: 250px;
    position: relative;
    margin: auto;

    .danger {
      position: absolute;
      left: 60px;
      top: 40px;
      height: 100px;
      width: 100px;
    }
  }
  .link {
    text-decoration: none;
    color: black;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .loginFailedModal {
    gap: 20px;
    border-radius: 20px;
    color: black;
    padding: 10px;
    .modal-content {
      max-width: 650px;
      margin: auto;
      box-shadow: 0px 10px 15px rgba(71, 71, 71, 0.1);
      border-radius: 20px;
      padding: 0px;
    }
    .modal-header {
      border: none;
    }
    .error_heading {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      /* identical to box height */

      letter-spacing: -0.03em;

      /* 메인텍스트 */

      color: #272727;
    }
    .error-text {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      text-align: center;

      /* 메인텍스트 */

      color: #272727;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 7.5px;
      .button {
        min-width: 130px;
        height: 50px;
        width: 50%;
      }
    }
    .error_image {
      background-image: url(../../../assets/images/ResultModal.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 160px;
      width: 200px;
      position: relative;

      .danger {
        position: absolute;
        left: 60px;
        top: 40px;
        height: 100px;
        width: 100px;
      }
    }
    .link {
      margin-bottom: 20px;
    }
  }
}
