// For Extra large screens 4K resolution 1921px and more
@media screen and (max-device-width: 4096px) {
  .report-types-wrapper {
    background: #FFFFFF;
    z-index: 9;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    position: absolute;
    left: -100px;
    width: 705px;
    top: 75px;
    height: 80vh;
    overflow-y: scroll;
    .report-type-div {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 20px;
    }
    .report-type-heading {
      /* 16_semibold */
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.03em;
      /* subtext_1 */
      color: #575757;
    }
    .report-type-description {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.03em;
      /* subtext_1 */
      color: #575757;
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      .button {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 55px;
        width: 100px;
        padding: 8px 4px;
        gap: 4px;
        /* Group 1_Ae */
        background: #d00019;
        border-radius: 8px;
        position: relative;
        .button_heading {
          font-family: "Pretendard";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */
          text-align: center;
          letter-spacing: -0.03em;
          color: #ffffff;
        }
        .button_content {
          font-family: "Pretendard";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */
          letter-spacing: -0.03em;
          color: #ffffff;
        }
      }
    }
  }
}


// For Small screens, laptops 769px — 1024px
@media screen and (max-device-width: 1024px) {
  .report-types-wrapper {
    position: absolute;
    left: -200px;
    width: 700px;
  }
}

// For iPads, Tablets 481px — 768px:
@media screen and (max-device-width: 768px) {
  .report-types-wrapper {
    position: relative;
    right: 0px;
    left: -100px;
    top: 0px;
    width: 300px;
    .buttons {
      flex-wrap: wrap;
      gap: 5px;
      padding: 4px;
      .button {
        height: 50px;
        padding: 8px 12px;
        .button_heading {
          font-size: 12px;
          line-height: 15px;
        }
        .button_content {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
  }
}

// For Mobiles and small screen 481px and less:
@media screen and (min-device-width: 220px) and (max-device-width: 480px) {
  .report-types-wrapper {
    width: 265px;
  }
}
