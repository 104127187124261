.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 40px;
  gap: 10px;
  background: #005be2;
  border: none;
  border-radius: 8px;
  color: white;

  cursor: pointer;
  display: flex;
  justify-content: center;

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
  &:hover {
    background-color: #0554ca;
  }

  &.button2 {
    background: #005be2;
    border-radius: 100px;

    &:hover {
      background-color: #0554ca;
    }
  }

  &.button3 {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #005be2;
    border-radius: 8px;
    color: #005be2;

    &:hover {
      background-color: #005be2;
      color: white;
      border: none;
    }
  }
}

// @media screen and (max-device-width: 1200px) {
//   .button-container {
//     padding: 12px 30px;
//     gap: 10px;
//   }
// }

// @media screen and (max-device-width: 1024px) {
//   .button-container {
//     padding: 8px 18px;
//     gap: 10px;
//   }
// }

// @media screen and (max-device-width: 768px) {
//   .button-container {
//     padding: 8px 20px;
//     gap: 10px;
//   }
// }
