.find-email-modal-wrapper {
  .modal-content {
    max-width: 760px;
    margin: auto;
    box-shadow: 0px 10px 15px rgba(71, 71, 71, 0.1);
    border-radius: 20px;
    padding: 25px 15px;
  }

  .modal-header {
    border: none;
  }

  .find-email-heading {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    /* 메인텍스트 */
    color: #272727;
  }
  .find-email-text {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #272727;
  }

  .initailCont {
    margin: 60px 0 50px 0;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 8px;
    padding: 1.4rem 1.2rem 1.6rem 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: center;
      padding-left: 12px;
      .textCont {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        margin-left: 0.7rem;

        :nth-child(1) {
          font-family: "Pretendard";
          font-style: normal;
          font-weight: 600;
          font-size: 26px;
          line-height: 31px;
          color: #272727;
        }
        :nth-child(2) {
          margin-top: 0.2rem;
          font-family: "Pretendard";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          /* identical to box height */
          /* 메인텍스트 */
          color: #272727;
        }
      }
    }
    .authenticateButton {
      font-family: "Pretendard";
      width: 114px;
      height: 50px;
      background: #005be2;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.03em;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .findByEmailForm {
    padding: 0;
    .input-label {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #272727;
      & > span {
        color: #ff4d4f;
      }
    }

    .input-cont {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 1rem;

      & .input-field {
        height: 70px;
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        width: 100%;
        padding: 1rem;
        text-align: left;
        border: 1.5px solid #cccccc;
        border-radius: 8px;
        color: #999999;

        &:focus-visible {
          outline: none;
        }
      }
    }

    .findEmailBtn {
      height: 60px;
      background: #005be2;
      border-radius: 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
  }

  .emailFoundForm {
    padding: 1.5rem 2rem;
    .borderLine {
      background: #ccc;
      height: 1px;
      margin-top: 0.5rem;
    }

    .radioCont {
      display: inline-flex;
      align-items: center;

      input[type="radio"] {
        margin-top: 3px;
      }

      label {
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        /* identical to box height, or 125% */

        text-align: center;

        margin-left: 1rem;
      }
    }

    .btns {
      margin-top: 6.25rem;
      margin-bottom: 1rem;
      .loginBtn {
        background: #005be2;
        border-radius: 8px;
        width: 75%;
        height: 60px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
      }

      .findPasswordBtn {
        font-family: "Pretendard";

        margin-top: 2rem;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #272727;
        cursor: pointer;
      }
    }
  }

  // -------------------------------------------------------

  .emailNotFoundCont {
    padding: 2.75rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .checkBtn {
      width: 240px;
      height: 60px;
      background: #005be2;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }

    .error_image {
      margin: 2.5rem 0 1rem 20px;
      background-image: url(../../../assets/images/ResultModal.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 180px;
      width: 280px;
      position: relative;
      .danger {
        position: absolute;
        left: 58px;
        top: 18px;
        height: 150px;
        width: 150px;
      }
    }

    .emailNotFoundbtnCont {
      display: flex;
      align-items: center;

      .btn {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #272727;

        &:active {
          border: none;
          outline: none;
          background-color: transparent;
        }
      }

      .separator {
        width: 1.5px;
        height: 19px;
        background-color: #cccccc;
      }
    }
  }

  .link {
    text-decoration: none;
    color: black;
  }
  .error {
    color: red;
    margin-top: 0.2rem;
  }
}

@media screen and (max-width: 768px) {
  .find-email-modal-wrapper {
    .modal-content {
      max-width: 600px;
      margin: 0 10px;
      box-shadow: 0px 10px 15px rgba(71, 71, 71, 0.1);
      border-radius: 20px;
      padding: 0px;
    }

    .find-email-heading {
      font-size: 24px;
      line-height: 29px;
    }
    .find-email-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }

    .initailCont {
      margin: 28px auto 42px auto;
      flex-direction: column;
      padding: 1.2rem 1rem 1.2rem 1.2rem;
      width: 92%;

      .left {
        justify-content: flex-start;
        width: 100%;
        gap: 10px;
        padding-left: 0px;
        padding-bottom: 17px;
        .textCont {
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          margin-left: 0.7rem;

          :nth-child(1) {
            font-size: 20px;
            line-height: 24px;
          }
          :nth-child(2) {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
      .authenticateButton {
        width: 104%;
        height: 50px;
      }
    }

    .findByEmailForm {
      padding: 13px;
      .input-label {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
      }

      .input-cont {
        margin-top: 0.5rem;

        & .input-field {
          height: 50px;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
        }
      }

      .findEmailBtn {
        height: 50px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
      }
    }

    .emailFoundForm {
      padding: 1.25rem 1rem;
      .borderLine {
        background: #ccc;
        height: 1px;
        margin-top: 0;
      }

      .radioCont {
        display: inline-flex;
        align-items: center;

        input[type="radio"] {
          margin-top: 3px;
        }

        label {
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
        }
      }

      .btns {
        margin-top: 3.75rem;
        margin-bottom: 1.75rem;
        .loginBtn {
          width: 101%;
          height: 50px;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
        }

        .findPasswordBtn {
          margin-top: 1rem;
        }
      }
    }

    // -------------------------------------------------------

    .emailNotFoundCont {
      padding: 1.25rem 0 2.5rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .checkBtn {
        width: 145px;
        height: 50px;
      }

      .error_image {
        margin: 2.25rem 0 0 0;
        background-image: url(../../../assets/images/ResultModal.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 130px;
        width: 200px;
        position: relative;
        .danger {
          position: absolute;
          left: 40px;
          top: 7px;
          height: 100px;
          width: 100px;
        }
      }
      .emailNotFoundbtnCont {
        display: flex;
        align-items: center;

        .btn {
          font-size: 14px;
          line-height: 17px;
        }

        .separator {
          width: 1.5px;
          height: 15px;
          background-color: #cccccc;
        }
      }
    }
  }
}
