.searchContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: #ffffff;
  padding: 50px 9.3% 140px 9.3%;
  // overflow-y: scroll;
}

.search {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.search-title {
  /* 검색 결과 */
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height */

  /* main text */

  color: #272727;

  margin-bottom: 15px;
}

.search-line {
  /* Line 2 */
  height: 0px;
  /* form_1 */
  border: 1px solid #cccccc;
  margin: 15px 0px 30px 0px;
}

.noResult {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  letter-spacing: -0.03em;

  /* subtext_2 */

  color: #999999;
}

.resultsSearch {
  margin-top: 40px;
}

// For iPads, Tablets 481px — 768px:
@media screen and (max-device-width: 768px) {
  .searchContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: #f5f5f5;
    padding: 30px 20px 100px 20px;
    // overflow-y: scroll;
  }

  .search {
    display: flex;
    flex-direction: column;
  }

  .search-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;

    /* 메인텍스트 */
    margin-bottom: 0px;
    color: #272727;
  }

  .search-line {
    /* Line 2 */
    height: 0px;
    /* form_1 */
    border: 1px solid #cccccc;
    margin: 15px 0px;
  }

  .noResult {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height, or 150% */
    letter-spacing: -0.03em;
  }

  .resultsSearch {
    margin-top: 30px;
  }
}
