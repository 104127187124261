.success-modal-wrapper {
  .modal-content {
    max-width: 760px;
    margin: auto;
    box-shadow: 0px 10px 15px rgba(71, 71, 71, 0.1);
    border-radius: 20px;
    padding: 20px;
  }

  .modal-header {
    border: none;
  }

  .input-icons svg {
    position: absolute;
  }
  .success-text {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    text-align: center;

    /* 메인텍스트 */

    color: #272727;
  }
}

@media screen and (max-width: 768px) {
  .success-modal-wrapper {
    .modal-content {
      padding: 0;
      margin: 0 10px;
    }
  }
}
