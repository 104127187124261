.forget-password-modal-wrapper {
  .modal-content {
    max-width: 760px;
    margin: auto;
    box-shadow: 0px 10px 15px rgba(71, 71, 71, 0.1);
    border-radius: 20px;
    padding: 25px 15px;
  }

  .modal-header {
    border: none;
  }

  .input-icons svg {
    position: absolute;
    margin-left: 0.7rem;
  }

  .input-icons {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .icon {
    margin: 5px;
  }

  .input-field {
    width: 100%;
    padding: 23px 0px 23px 80px;
    text-align: left;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
  }
  .forget-heading {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    /* identical to box height */

    /* main text */

    color: #272727;
  }
  .forget-text {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */
    text-align: center;
    /* main text */
    color: #272727;
  }
  .link {
    text-decoration: none;
    color: black;
  }
  .error {
    color: red;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    gap: 6px;
    align-items: center;
    margin-top: 10px;
    &::before {
      display: block;
      height: 16px;
      width: 16px;
      align-self: flex-start;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M8.75 8.75H7.25V4.25H8.75M8.75 11.75H7.25V10.25H8.75M8 0.5C7.01509 0.5 6.03982 0.693993 5.12987 1.0709C4.21993 1.44781 3.39314 2.00026 2.6967 2.6967C1.29018 4.10322 0.5 6.01088 0.5 8C0.5 9.98912 1.29018 11.8968 2.6967 13.3033C3.39314 13.9997 4.21993 14.5522 5.12987 14.9291C6.03982 15.306 7.01509 15.5 8 15.5C9.98912 15.5 11.8968 14.7098 13.3033 13.3033C14.7098 11.8968 15.5 9.98912 15.5 8C15.5 7.01509 15.306 6.03982 14.9291 5.12987C14.5522 4.21993 13.9997 3.39314 13.3033 2.6967C12.6069 2.00026 11.7801 1.44781 10.8701 1.0709C9.96018 0.693993 8.98491 0.5 8 0.5Z' fill='%23FF4D4F'/%3E%3C/svg%3E");
    }
  }
}

@media screen and (max-width: 768px) {
  .forget-password-modal-wrapper {
    .modal-content {
      margin: 0 10px;
      max-width: 600px;
      box-shadow: 0px 10px 15px rgba(71, 71, 71, 0.1);
      border-radius: 20px;
      padding: 0px;
    }

    .modal-header {
      border: none;
    }

    .input-icons svg {
      position: absolute;
      margin-left: 0.7rem;
    }

    .input-icons {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .icon {
      margin: 5px;
    }

    .input-field {
      width: 100%;
      padding: 10px 50px;
      text-align: left;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
    .forget-heading {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.03em;

      /* 메인텍스트 */

      color: #272727;
    }
    .forget-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
    .link {
      text-decoration: none;
      color: black;
    }
    .error {
      margin-top: 0.2rem;
      color: red;
    }
  }
}
