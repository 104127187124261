.resetPasswordWrongEmailModalWrapper {
  .modal-content {
    max-width: 600px;
    margin: auto;
    box-shadow: 0px 10px 15px rgba(71, 71, 71, 0.1);
    border-radius: 20px;
    padding: 20px;
  }
  .resetPasswordWrongEmailModal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    border-radius: 20px;
    color: black;
    .modal-header {
      border: none;
    }
    .error_heading {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      /* identical to box height */

      /* main text */

      color: #272727;
    }
    .error-text {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height, or 150% */

      text-align: center;

      /* main text */

      color: #272727;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      .button {
        padding: 10px 70px;
      }
    }
    .error_image {
      background-image: url(../../../assets/images/ResultModal.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 160px;
      width: 250px;
      position: relative;
      .danger {
        position: absolute;
        left: 60px;
        top: 40px;
        height: 100px;
        width: 100px;
      }
    }
    .link {
      text-decoration: none;
      color: black;
    }
  }
}
