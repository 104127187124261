// global styling

// For Extra large screens, TV  1201px and more —

// Main Navbar Container
.user_navbar {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 88px;
  background: #ffffff;
  box-shadow: 3px 4px 20px rgba(194, 203, 214, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 9.3%;

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 17%;
    cursor: pointer;

    .logo {
      display: flex;
      align-items: center;

      img {
        height: 50px;
      }
    }
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 43%;
    gap: 31px;

    .dropdown {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      cursor: pointer;
    }

    a {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.03em;
      color: #272727;
      text-decoration: none;
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    gap: 6%;

    .icons {
      height: 25px;
      width: 25px;
      cursor: pointer;

      img {
        margin: 0 auto;
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }

    .log_in {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.03em;
      color: #272727;
      padding: 10px;
      cursor: pointer
    }
  }

  .hamburger_menu {
    .bar_icon {
      display: none;
    }
  }

  // Mobile Menu
  .mobile_sidebar {
    display: none;
  }
}

// For Desktops, large screens 1025px — 1200px
@media screen and (max-device-width: 1200px) {
  .user_navbar {
    height: 75px;
    padding: 0px 2%;

    .left {
      width: 12%;

      .logo {
        img {
          height: 40px;
        }
      }
    }

    .link {
      width: 40%;
    }
  }
}

// For Small screens laptops, 769px — 1024px
@media screen and (max-device-width: 1024px) {
  .user_navbar {
    padding: 0px 20px;
    height: 70px;

    .left {
      width: 15%;
    }

    .links {
      width: 43%;
    }

    .right {
      width: 42%;
    }
  }
}

// For iPads, Tablets and mobile 481px — 1024px:
@media screen and (max-width: 1024px) {
  .user_navbar {
    height: 60px;
    justify-content: space-between;
    padding: 0px 20px;

    .left {
      justify-content: left;
      width: auto;
    }

    .links {
      display: none;
    }

    .right {
      display: none;
    }

    .hamburger_menu {
      .bar_icon {
        display: block;
        justify-content: right;
        font-size: 20px;
        cursor: pointer;
      }
    }

    // ====================================
    //              Mobile View
    // ====================================

    .mobile_sidebar {
      background: #32474a;
      position: fixed;
      right: 0px;
      top: 0px;
      border-radius: 10px;
      width: 340px;
      height: 100vh;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      background: #ffffff;
      box-shadow: 3px 4px 20px rgba(194, 203, 214, 0.15);
      color: #272727;
      gap: 25px;
      padding: 20px;
      margin-right: -10px;

      .x-icon {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        font-size: 20px;
        cursor: pointer;
        width: 100%;
      }

      // When User Logout
      .loginStatus {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
        flex-direction: column;
        text-align: left;

        .login_heading {
          font-family: "Pretendard";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          /* identical to box height */

          text-align: center;

          /* main text */

          color: #272727;
        }

        .login_text {
          font-family: "Pretendard";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;

          /* dark blue */

          color: #1c319f;
        }

        .signup_button {
          background: #ffffff;
          /* main color */
          border: 1px solid#005BE2;
          border-radius: 8px;
          height: 50px;
          width: 100%;
          font-family: "Pretendard";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.03em;

          /* main color */

          color: #005be2;
        }
      }

      // When User Login

      .logged_Status {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .profile_pic {
          // width: 108px;
          // height: 72px;
          align-items: center;
          display: flex;
          justify-content: center;
        }

        .logged_Status_profile {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          gap: 10px;
          flex-direction: column;
          width: 212px;
        }

        .logged_heading {
          font-family: "Pretendard";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          /* identical to box height */

          text-align: left;

          /* main text */

          color: #272727;
        }

        .logged_text {
          font-family: "Pretendard";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          text-align: left;
          /* dark blue */
          color: #1c319f;
        }
      }

      .inspect_button {
        background: #005be2;
        /* main color */
        border: 1px solid#005BE2;
        border-radius: 8px;
        padding: 15px;
        width: 100%;
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.03em;

        /* main color */

        color: #ffffff;
      }

      .links {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
        flex-direction: column;
        text-align: left;

        a {
          font-family: "Pretendard";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height */
          letter-spacing: -0.03em;
          /* main text */
          color: #272727;
          text-decoration: none;
        }

        hr {
          border: 1px solid#3e3e3e;
          width: 260px;
          margin: 0;
        }
      }

      .login_button {
        width: 100%;
        // margin-top: 150px;
        padding: 19px;
        background: #ffffff;
        /* line_1 */
        border: 1px solid#EBEBEB;
        border-radius: 50px;
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */

        letter-spacing: -0.03em;

        /* subtext_1 */

        color: #575757;
      }
    }
  }
}

// For Mobiles and small screen 481px and less:
@media screen and (min-device-width: 220px) and (max-device-width: 480px) {

  // Mobile Menu
  .user_navbar {
    padding: 0px 20px;

    .mobile_sidebar {
      width: 330px;
    }
  }
}