.textFont {
  font-family: "Pretendard";
}

.midSection {
  display: flex !important;
  flex-direction: row !important;
}

.bgVector {
  position: absolute;
  top: 57%;
  bottom: 24.34%;
}
