.itemContainerSearch {
  width: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.titleSearch {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  letter-spacing: -0.03em;

  color: #1890ff;
}

.descriptionSearch {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  margin-bottom: 29px;

  letter-spacing: -0.03em;

  /* subtext_2 */

  color: #999999;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  height: 80px;
  margin: 70px auto 0px auto;
}
.buttonsClassName {
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 1px 3px 20px rgba(194, 203, 214, 0.2);
  border-radius: 20px;
  height: 35px;
  width: 35px;
  margin: 0px 20px;
}
.buttonsLabelClassName {
  font-size: 20px;
  text-decoration: none;
  text-align: center;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.breakClassName {
  display: flex;
  gap: 100px;
  padding: 0px 200px;
}

.pageLinkClassName {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */
  /* 메인텍스트 */
  color: #272727;
  text-decoration: none;
}
.active {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  /* 메인컬러 */

  color: #005be2;
}

@media screen and (max-width: 768px) {
  .titleSearch {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.03em;

    /* Conditional / menu-item-text-selected */

    color: #1890ff;
  }

  .descriptionSearch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    gap: 0px;
    margin: 32px auto -10px auto;
  }
  .buttonsClassName {
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 1px 3px 20px rgba(194, 203, 214, 0.2);
    border-radius: 20px;
    height: 35px;
    width: 35px;
    margin: 0px 0px;
  }
  .buttonsLabelClassName {
    font-size: 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .breakClassName {
    display: flex;
    gap: 100px;
    padding: 0px 200px;
  }

  .pageLinkClassName {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */
    /* 메인텍스트 */
    color: #272727;
    text-decoration: none;
  }
  .active {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */

    /* 메인컬러 */

    color: #005be2;
  }
}
