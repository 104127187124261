.errorModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  border-radius: 20px;
  color: black;
  padding: 50px;
  gap: 45px;
  transform: translateY(-4);

  .modal-content {
    max-width: 760px;
    margin: auto;
    box-shadow: 0px 10px 15px rgba(71, 71, 71, 0.1);
    border-radius: 20px;
    padding: 20px;
  }
  .error-text {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */
    text-align: center;
    /* 메인텍스트 */
    color: #272727;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}

@media screen and (max-width: 768px) {
  .errorModal {
    transform: translateY(-8);
    padding: 50px 0px;
    gap: 20px;

    .modal-content {
      padding: 0;
      margin: 0 10px;
    }
  }
}
